export const postContants = {
  VIEW_POST_REQUEST: "VIEW_POST_REQUEST",
  VIEW_POST_FAILURE: "VIEW_POST_FAILURE",
  VIEW_POST_SUCCESS: "VIEW_POST_SUCCESS",

  VIEW_ALL_POST_REQUEST: "VIEW_ALL_POST_REQUEST",
  VIEW_ALL_POST_FAILURE: "VIEW_ALL_POST_FAILURE",
  VIEW_ALL_POST_SUCCESS: "VIEW_ALL_POST_SUCCESS",

  RESET_STATE: "RESET_STATE",
};

export const userContants = {
  RESET_USER_MESSAGE: "RESET_USER_MESSAGE",
  EMAIL_CHECKER_REQUEST: "EMAIL_CHECKER_REQUEST",
  EMAIL_CHECKER_FAILURE: "EMAIL_CHECKER_FAILURE",
  EMAIL_CHECKER_SUCCESS: "EMAIL_CHECKER_SUCCESS",

  CHECK_USER_STATUS_REQUEST: "CHECK_USER_STATUS_REQUEST",
  CHECK_USER_STATUS_SUCCESS: "CHECK_USER_STATUS_SUCCESS",
  CHECK_USER_STATUS_FAILURE: "CHECK_USER_STATUS_FAILURE",

  UPDATE_STATE_REQUEST: "UPDATE_STATE_REQUEST",
  UPDATE_STATE_SUCCESS: "UPDATE_STATE_SUCCESS",

  USER_UPDATE_REQUEST: "USER_UPDATE_REQUEST",
  USER_UPDATE_SUCCESS: "USER_UPDATE_SUCCESS",

  USER_IS_PRESENT_OR_NOT_REQUEST: "USER_IS_PRESENT_OR_NOT_REQUEST",
  USER_IS_PRESENT_OR_NOT_SUCCESS: "USER_IS_PRESENT_OR_NOT_SUCCESS",
  ADD_USER_REQUEST: "ADD_USER_REQUEST",
  ADD_USER_SUCCESS: "ADD_USER_SUCCESS",
  ADD_USER_FAILURE: "ADD_USER_FAILURE",
  GET_USER_PROFILE_REQUEST: "GET_USER_PROFILE_REQUEST",
  GET_USER_PROFILE_SUCCESS: "GET_USER_PROFILE_SUCCESS",
  GET_USER_PROFILE_FAILURE: "GET_USER_PROFILE_FAILURE",

  UPDATE_PAYMENT_STATUS_REQUEST: "UPDATE_PAYMENT_STATUS_REQUEST",
  UPDATE_PAYMENT_STATUS_SUCCESS: "UPDATE_PAYMENT_STATUS_SUCCESS",
  UPDATE_PAYMENT_STATUS_FAILURE: "UPDATE_PAYMENT_STATUS_FAILURE",

  RESET_USER_STATUS: "RESET_USER_STATUS",
};

export const achievemntsConstant = {
  GET_ACHIVE_REQUEST: "GET_ACHIVE_REQUEST",
  GET_ACHIVE_SUCCESS: "GET_ACHIVE_SUCCESS",
  GET_ACHIVE_FAILURE: "GET_ACHIVE_FAILURE",
};

export const authConstants = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",

  ADMIN_LOGIN_REQUEST: "ADMIN_LOGIN_REQUEST",
  ADMIN_LOGIN_SUCCESS: "ADMIN_LOGIN_SUCCESS",
  ADMIN_LOGIN_FAILURE: "ADMIN_LOGIN_FAILURE",

  LOGOUT_REQUEST: "LOGOUT_REQUEST",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "LOGOUT_FAILURE",
};

export const placeConstants = {
  GET_STATE_REQUEST: "GET_STATE_REQUEST",
  GET_STATE_SUCCESS: "GET_STATE_SUCCESS",
  GET_STATE_FAILURE: "GET_STATE_FAILURE",

  GET_CITY_REQUEST: "GET_CITY_REQUEST",
  GET_CITY_SUCCESS: "GET_CITY_SUCCESS",
  GET_CITY_FAILURE: "GET_CITY_FAILURE",

  GET_DIST_REQUEST: "GET_DIST_REQUEST",
  GET_DIST_SUCCESS: "GET_DIST_SUCCESS",
  GET_DIST_FAILURE: "GET_DIST_FAILURE",

  CLEAN_CITY: "CLEAN_CITY",
  CLEAN_DISTRICT: "CLEAN_DISTRICT",

  CLEAN_ALL_STATE: "CLEAN_ALL_STATE",
};
export const jobConstruct = {
  ADD_JOB_REQUEST: "ADD_JOB_REQUEST",
  ADD_JOB_SUCCESS: "ADD_JOB_SUCCESS",
  ADD_JOB_FAILURE: "ADD_JOB_FAILURE",

  RESET_JOB: "RESET_JOB",
  GET_ALL_JOBS_REQUEST: "GET_ALL_JOBS_REQUEST",
  GET_ALL_JOBS_SUCCESS: "GET_ALL_JOBS_SUCCESS",
  GET_ALL_JOBS_FAILURE: "GET_ALL_JOBS_FAILURE",

  GET_JOB_REQUEST: "GET_JOB_REQUEST",
  GET_JOB_SUCCESS: "GET_JOB_SUCCESS",
  GET_JOB_FAILURE: "GET_JOB_FAILURE",

  GET_MY_JOB_REQUEST: "GET_MY_JOB_REQUEST",
  GET_MY_JOB_SUCCESS: "GET_MY_JOB_SUCCESS",
  GET_MY_JOB_FAILURE: "GET_MY_JOB_FAILURE",
};
export const businessConstruct = {
  ADD_BUSINESS_REQUEST: "ADD_BUSINESS_REQUEST",
  ADD_BUSINESS_SUCCESS: "ADD_BUSINESS_SUCCESS",
  ADD_BUSINESS_FAILURE: "ADD_BUSINESS_FAILURE",

  RESET_BUSINESS: "RESET_BUSINESS",
  GET_ALL_BUSINESS_REQUEST: "GET_ALL_BUSINESS_REQUEST",
  GET_ALL_BUSINESS_SUCCESS: "GET_ALL_BUSINESS_SUCCESS",
  GET_ALL_BUSINESS_FAILURE: "GET_ALL_BUSINESS_FAILURE",

  GET_BUSINESS_REQUEST: "GET_BUSINESS_REQUEST",
  GET_BUSINESS_SUCCESS: "GET_BUSINESS_SUCCESS",
  GET_BUSINESS_FAILURE: "GET_BUSINESS_FAILURE",

  GET_MY_BUSINESS_REQUEST: "GET_MY_BUSINESS_REQUEST",
  GET_MY_BUSINESS_SUCCESS: "GET_MY_BUSINESS_SUCCESS",
  GET_MY_BUSINESS_FAILURE: "GET_MY_BUSINESS_FAILURE",
};

export const jobApplyConstants = {
  JOB_APPLY_REQUEST: "JOB_APPLY_REQUEST",
  JOB_APPLY_SUCCESS: "JOB_APPLY_SUCCESS",
  JOB_APPLY_FAILURE: "JOB_APPLY_FAILURE",

  GET_APPLYED_USERS_REQUEST: "GET_APPLYED_USERS_REQUEST",
  GET_APPLYED_USERS_SUCCESS: "GET_APPLYED_USERS_SUCCESS",
  GET_APPLYED_USERS_FAILURE: "GET_APPLYED_USERS_FAILURE",
};

export const chalchitramConstants = {
  GET_ALL_RANDOM_VIDEO_REQUEST: "GET_ALL_RANDOM_VIDEO_REQUEST",
  GET_ALL_RANDOM_VIDEO_SUCCESS: "GET_ALL_RANDOM_VIDEO_SUCCESS",
  GET_ALL_RANDOM_VIDEO_FAILURE: "GET_ALL_RANDOM_VIDEO_FAILURE",

  GET_ALL_LATEST_VIDEO_REQUEST: "GET_ALL_LATEST_VIDEO_REQUEST",
  GET_ALL_LATEST_VIDEO_SUCCESS: "GET_ALL_LATEST_VIDEO_SUCCESS",
  GET_ALL_LATEST_VIDEO_FAILURE: "GET_ALL_LATEST_VIDEO_FAILURE",

  GET_ALL_CATEGORY_VIDEO_REQUEST: "GET_ALL_CATEGORY_VIDEO_REQUEST",
  GET_ALL_CATEGORY_VIDEO_SUCCESS: "GET_ALL_CATEGORY_VIDEO_SUCCESS",
  GET_ALL_CATEGORY_VIDEO_FAILURE: "GET_ALL_CATEGORY_VIDEO_FAILURE",

  CLEAN_CATEGORY_DATA: "CLEAN_CATEGORY_DATA",

  SELECTED_CATEGORY: "SELECTED_CATEGORY",

  GET_COURSE_VIDEOES_REQUEST: "GET_COURSE_VIDEOES_REQUEST",
  GET_COURSE_VIDEOES_SUCCESS: "GET_COURSE_VIDEOES_SUCCESS",
  GET_COURSE_VIDEOES_FAILURE: "GET_COURSE_VIDEOES_FAILURE",

  GET_HISTORY_VIDEOES_REQUEST: "GET_HISTORY_VIDEOES_REQUEST",
  GET_HISTORY_VIDEOES_SUCCESS: "GET_HISTORY_VIDEOES_SUCCESS",
  GET_HISTORY_VIDEOES_FAILURE: "GET_HISTORY_VIDEOES_FAILURE",
};
